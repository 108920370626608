import React, {useEffect, useMemo, useState} from 'react';
import {Button, MobileStepper} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import {virtualize} from 'react-swipeable-views-utils';
import {StepperPlaceholder} from 'app/components/styles/swipeableViews/StepperPlaceholder';
const VirtualizeSwipeableViews = virtualize(SwipeableViews);

interface SwipeableViewsStepperProps {
  views: any[];
  viewRender: (obj: {index: number}) => React.ReactElement;
}

export default function SwipeableViewsStepper({views, viewRender}: SwipeableViewsStepperProps) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = useMemo(() => views.length - 1, [views.length]);

  const handleNext = () => {
    if (activeStep < maxSteps) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleIndexChange = (index: number) => {
    setActiveStep(index);
  };

  useEffect(() => {
    if (activeStep > maxSteps) {
      setActiveStep(maxSteps);
    }
  }, [maxSteps]);

  return (
    <>
      <VirtualizeSwipeableViews
        index={activeStep}
        onChangeIndex={handleIndexChange}
        overscanSlideBefore={activeStep > 0 ? 1 : 0}
        overscanSlideAfter={activeStep < maxSteps ? 1 : 0}
        slideCount={views.length}
        slideRenderer={viewRender}
      />
      {views.length > 1 ? (
        <MobileStepper
          steps={views.length}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button size='small' onClick={handleNext} disabled={activeStep >= maxSteps}>
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStep <= 0}>
              <KeyboardArrowLeft />
            </Button>
          }
        />
      ) : (
        <StepperPlaceholder />
      )}
    </>
  );
}
